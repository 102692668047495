// import logo from './logo.svg'
import './App.css';
import Clock from './components/Clock';

function App() {
    return (
    <div className="App">
        <Clock/>
    </div>
    );
}

export default App;


